.headernav-wrap{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: 0;
    z-index: 999;
    width: 100%;
    padding-left: 50px;
    padding-right: 40px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
    transition: background-color 0.5s;
    .headernav-logo{
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        margin-right: auto;
        font-size: 50px;
        line-height: 1;
        font-family: Ebrima;
        font-style: italic;
        font-weight: bold;
    } 
    .headernav-item-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 64px;
        width: 10%;
        max-width: 130px;
        min-width: 100px;
        text-align: center;
        vertical-align: middle;
        padding-right: 10px;
        padding-left: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        transition: filter 0.5s;
        overflow: hidden;
        transition: background-color 0.5s;
    }
    .headernav-item-wrap.activated{
        border-bottom-width: 4px;
        border-bottom-style: solid;
    }
    .headernav-item-wrap:hover{
        filter: brightness(0.8);
    }
}