@keyframes bubble-float{
    0% {transform: translateY(0);}
    100% {transform: translateY(-700px) rotate(600deg);}
}

.rps-top-background{
    position: fixed;
    width: 100%;
    height: 400px;
    background: linear-gradient(180deg, #5F0731, #7a266d);
    overflow: hidden;
    .background-bubbles{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        li{
            position: absolute;
            list-style: none;
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: rgba(255,255,255,0.15);
            bottom: -160px;
            animation: bubble-float 25s infinite;
            transition-timing-function: linear;
            box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
        }
        li:nth-child(1){
            left: 10%;
        }
        li:nth-child(2){
            left: 20%;
            width: 80px;
            height: 80px;
            animation-delay: 2s;
            animation-duration: 17s
        }
        li:nth-child(3){
            left: 25%;
            animation-delay: 4s;
        }
        li:nth-child(4){
            left: 40%;
            width: 60px;
            height: 60px;
            animation-duration: 22s;
            background-color: rgba(255,255,255,0.25);
        }
        li:nth-child(5){
            left: 70%;
        }
        li:nth-child(6){
            left: 80%;
            width: 120px;
            height: 120px;
            animation-delay: 3s;
            background-color: rgba(255,255,255,0.2);
        }
        li:nth-child(7){
            left: 32%;
            width: 160px;
            height: 160px;
            animation-delay: 7s;
        }
        li:nth-child(8){
            left: 55%;
            width: 20px;
            height: 20px;
            animation-delay: 15s;
            animation-duration: 40s;
        }
        li:nth-child(9){
            left: 55%;
            width: 10px;
            height: 10px;
            animation-delay: 2s;
            animation-duration: 40s;
            background-color: rgba(255,255,255,0.3);
        }
        li:nth-child(10){
            left: 90%;
            width: 160px;
            height: 160px;
            animation-delay: 11s;
        }
        li:nth-child(11){
            left: 65%;
            width: 300px;
            height: 300px;
            animation-duration: 50s;
        }
        li:nth-child(12){
            left: -10%;
            bottom: -100%;
            width: 500px;
            height: 500px;
            animation-duration: 80s;
        }
        li:nth-child(13){
            left: 45%;
            width: 20px;
            height: 20px;
            animation-delay: 4s;
            animation-duration: 30s;
        }
    }
}